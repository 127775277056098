import React from 'react';
import Img from "gatsby-image";
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faVolumeUp, faPlay } from '@fortawesome/free-solid-svg-icons';

const MediaTypeIcon = ({ type, url }) => {
  let icon = '';
  let text = '';
  let typeClass = '';

  if (type && type.indexOf('Video') !== -1) {
    icon = faPlay;
    text = 'Watch Full Video';
    typeClass = 'video-media-icon';
  } else if (type && type.indexOf('Audio') !== -1) {
    icon = faVolumeUp;
    text = 'Play Audio';
    typeClass = 'audio-media-icon';
  } else {
    icon = faExpand;
    text = 'View Full Image';
    typeClass = 'image-media-icon';
  }


  return (
    <div className={`media-icon ${typeClass}`}>
      <span className="icon"><FontAwesomeIcon icon={icon} className="mx-2" /></span>
      <span className="label">{text}</span>
    </div>
  );
};

const MediaGallery = ({ mediaItem, isThumbnail }) => {
  const media = mediaItem.id !== null && mediaItem;

  const {
    id,
    image,
    type,
    caption,
    description,
    author,
    display_date,
    source,
    media_type,
    url,
    thumbnail_url,
    preview_url,
    media_id,
  } = media;


  const isVideo = media_type && media_type.indexOf('Video') !== -1 && media_id !== null;
  const isRemoteVideo = (media_type && media_type.indexOf('Video') !== -1) && (type && type === 'remote_video');
  const imageUrl = isVideo ? preview_url : image ? image.gallery : '';
  const thumbnail = isVideo ? thumbnail_url : image ? image.thumbnail : '';

  const imageUrlCDN = _.replace(imageUrl, 'https://api.bcah.ca', 'https://bc-timeline-backend.azureedge.net');
  const thumbnailCDN = _.replace(thumbnail, 'https://api.bcah.ca', 'https://bc-timeline-backend.azureedge.net');

  var videoUrl = '';

  if (isVideo) {
    videoUrl = `https://cdn.jwplayer.com/videos/${media_id}.mp4`;
  }

  if (isRemoteVideo) {
    videoUrl = url;
  }


  if (typeof document !== 'undefined') {
    document.addEventListener('contextmenu', (e) => {
      if (e.target.tagName === 'IMG') {
        e.preventDefault();
        e.stopPropagation();
      }
    })
  }

  return (
    <>
      {isThumbnail ? (
        <div className="media-thumb-bg" style={{ backgroundImage: `url(${thumbnailCDN})` }} />
      ) : (
        <div className="story-slider-item" id={id}>
            {isVideo || isRemoteVideo ? (
              <a href={videoUrl} data-fancybox="slide" data-caption={caption} data-slide-img-id={id}>
                <div className="slide-video-thumbnail gallery-image" >
                  <img src={thumbnail_url} alt={description} />
                  <MediaTypeIcon type={media_type} />
                </div>
              </a>
            ) : (
                <a href={imageUrlCDN} data-fancybox="slide" data-caption={caption} data-slide-img-id={id}>
                  <div className="gallery-image">
                    <img src={imageUrlCDN} alt={description} />
                    <MediaTypeIcon type={media_type} />
                  </div>
                </a>
            )}
          <div className="caption">
            <div className="scrollable-caption">
                {caption && <p className="font-weight-semi">{caption}</p>}
                {display_date && (
                  <p className="media-date mb-0">
                    <small>
                      <strong className="text-uppercase">Date: </strong>
                    </small>
                    <em>{display_date}</em>
                  </p>
                )}

              {author && (
                <p className="media-author mb-0">
                  <small>
                    <strong className="text-uppercase">Author: </strong>
                  </small>
                    <em>{author.join(', ')}</em>
                  </p>
                )}
              {source && (
                <p className="media-source mb-0">
                  <small>
                    <strong className="text-uppercase">Source: </strong>
                  </small>
                  <em>{source}</em>
                </p>
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MediaGallery;
