import React, { Component, useEffect } from 'react';
import { graphql, Link } from "gatsby";
// import {  } from '@reach/router';
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faChevronDown, faPrint } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";
import WatchSeries from "../components/watchSeries";
import GalleryItem from "../components/storyGallery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundImage from "../components/backgroundImage";
import FooterStory from "../components/footerStory";
import SocialShare from "../components/socialShare";


const PrevArrow = (props) => {
	const { onClick } = props;
	return (
		<div className="slick-arrow prev-arrow" role="button" onClick={onClick} onKeyUp={onClick} tabIndex="0">
			<span className="arrow-icon"><FontAwesomeIcon icon={faChevronLeft} size="lg" color="white" /></span>
		</div>
	);
};

const NextArrow = (props) => {
	const { onClick } = props;
	return (
		<div className="slick-arrow next-arrow" role="button" onClick={onClick} onKeyUp={onClick} tabIndex="0">
			<span className="arrow-icon"><FontAwesomeIcon icon={faChevronRight} size="lg" color="white" /></span>
		</div>
	);
};

class Story extends Component {
	state = {
		nav1: null,
		nav2: null,
		contentHeight: 0,
		isReadMore: false,
		showAllContent: false,
	};

	componentDidMount() {
		window.scrollTo(0, 0);

		this.setState({
			nav1: this.slider1,
			nav2: this.slider2,
			contentHeight: this.storyContentRef.clientHeight,
			isReadMore: this.storyContentRef.clientHeight > 400,
		});

		window.addEventListener("resize", this.setContentReadBtn);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setContentReadBtn);
	}

	setContentReadBtn = () => {
		this.setState({
			contentHeight: this.storyContentRef.clientHeight,
		});

		if (this.state.contentHeight > 400) {
			this.setState({
				isReadMore: true,
			});
		} else {
			this.setState({
				isReadMore: false,
			});
		}
	};


	render() {

		const {
			decade,
			name,
			deck,
			display_date,
			geographic_area,
			heritage,
			story_stream,
			media,
			color_scheme,
			story_content,
			story_sources,
			keywords,
			language,
			publisher,
			coverage,
			rights_license,
			author,
			featured_image
		} = this.props.data.stories;

		const storyTags = { heritage } || {};

		const image = featured_image !== null ? featured_image[0].image.featured : '';

		const dataOptions = {
			url: this.props.location.href,
			title: `${name} - ${this.props.data.site.siteMetadata.title}`
		};

		let label;

		const { state } = this.props.location;
		const { fromSearch } = state || false;

		if (!fromSearch) {
			label = decade[0];
		} else {
			label = "Back to Search";
		}

		const goBack = () => {
			window.history.back();
		};

		const backLink = this.props.location.state !== null && fromSearch ? this.props.location.state.fromSearchUrl : `/${_.kebabCase(decade[0])}`;

		const settings = {
			infinite: true,
			speed: 450,
			arrows: true,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />,
			draggable: false,
			fade: false,
			useCSS: false,
			// useTransform: false,
		};

		const settingsThumb = {
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: media && media.length > 4 ? true : false,
			vertical: true,
			arrows: true,
			verticalSwiping: true,
			focusOnSelect: true,
			// lazyLoad: true,
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />,
			// centerPadding: media.length > 4 ? "20px" : "0px",
			// centerMode: media.length > 4 ? true : false,
		};

		const handleReadMore = (e) => {
			e.preventDefault();

			this.setState({
				showAllContent: !this.state.showAllContent,
			});
		};

		// const newName = _.split(name, new RegExp('[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]', 'g')).join('');
		const bgColor = {
			"dark-blue-scheme": "#1f323c",
			"orange-scheme": "#a55502",
			"blue-scheme": "#89a6aa"
		};

		return (
			<Layout>
				<SEO
					title={dataOptions.title}
					url={this.props.location.href}
					description={deck}
					story={true}
					image={image}
					theme_color={bgColor[color_scheme]}
					keywords={keywords && keywords.join(", ")}
				/>

				<div className="story-wrapper main-fixed-wrapper">
					<div className="story-header-wrapper reveal-animate">
						<Container fluid="lg">
							<Row className="align-items-end">

								<Col xs={12} md={4} className="d-print-none">
									<a href={backLink} className="back-link mb-5 mb-md-0">
										<span className="explore-arrow position-relative">
											<span className="long-arrow left-arrow" />
											{label && <span className="label">{label}</span>}
										</span>
									</a>
								</Col>
								<Col xs={12} md className="print-mode text-md-right text-center text-center-print">
									{name && <h1 className={`story-heading  ${color_scheme}`}><span>{name}</span></h1>}
								</Col>
							</Row>
							<Row>
								<Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} className="print-mode-only">
									{deck && <p className="story-deck text-center text-md-right text-center-print">{deck}</p>}
									{display_date && (
										<p className="story-date text-uppercase text-center text-md-right text-center-print">
											<span className="font-weight-semi">Date: </span>
											<span className="font-italic">{display_date}</span>
										</p>
									)}
								</Col>
							</Row>
						</Container>
					</div>

					{media && media.length > 0 && (
						<div className={`story-slider-wrapper d-print-none reveal-animate ${color_scheme}`}>
							<Container fluid="lg">
								<Row noGutters>
									<Col xs={12} xl={10}>
										<Slider
											className="story-main-slider"
											{...settings}
											asNavFor={this.state.nav2}
											ref={(slider) => (this.slider1 = slider)}
										>
											{media.map((gallery, index) => gallery.id != null && <GalleryItem mediaItem={gallery} key={gallery.id} imgId={index} />)}


										</Slider>
									</Col>
									<Col xs={12} xl={2} className="d-none d-xl-block">
										<Slider
											className="slider-nav"
											{...settingsThumb}
											asNavFor={this.state.nav1}
											ref={(slider) => (this.slider2 = slider)}
										>
											{media.map((gallery) => gallery.id != null && <GalleryItem key={gallery.id} mediaItem={gallery} isThumbnail />)}
										</Slider>
									</Col>
								</Row>
							</Container>
						</div>
					)}

					<div className="story-content-wrapper reveal-animate">
						<Container fluid="lg">
							<Row>
								<Col>
									<div
										className={`story-content-container ${this.state.contentHeight <= 400 ? "show-less" : ""} ${this.state.showAllContent ? "show-all" : ""
											}`}
										style={{
											maxHeight: this.state.showAllContent && this.state.contentHeight >= 400 ? "100%" : "400px",
										}}
									>
										<div
											className="story-content"
											ref={(storyContentRef) => {
												this.storyContentRef = storyContentRef;
											}}
											data-height={this.state.contentHeight}
											dangerouslySetInnerHTML={{ __html: story_content }}
										/>
									</div>
									{this.state.isReadMore && (
										<div className={`read-more-btn d-print-none ${this.state.showAllContent ? "active" : ""}`}>
											<button type="button" onClick={handleReadMore} className={`show-button ${color_scheme}`}>
												<span>{this.state.showAllContent ? "Read Less" : "Read More"}</span>
												<span><FontAwesomeIcon icon={faChevronDown} className="mx-2 align-top" /></span>
											</button>
										</div>
									)}

									<div className="story-info mt-3">
										{Object.keys(storyTags).length > 0 && (
											<small className="story-tags">
												<span className="text-uppercase font-weight-semi">Tags:</span>
												{Object.keys(storyTags).map(
													(type) => storyTags[type] !== null
														&& storyTags[type].map((tag, index) => (
															<a key={index} href={`/search?${type}=${tag}`}>
																{tag}
															</a>
														)),
												)}
											</small>
										)}

										{author && (
											<p className="story-author mb-1">
												<small>
													<span className="text-uppercase font-weight-semi mr-1">Author:</span>
													<em>{author.join(", ")}</em>
												</small>
											</p>
										)}

									</div>

									<div className="d-print-none mt-4">
										<button type="button" onClick={() => window.print()} className={`story-print-button ${color_scheme}`}>
											<FontAwesomeIcon icon={faPrint} className="mr-2 align-middle" />
											<span>Print Story</span>
										</button>
									</div>
								</Col>
								{geographic_area !== null && (
									<Col lg={{ span: 3, offset: 1 }} xs={{ span: 10, offset: 1 }} className=" d-print-none">
										<div className="story-map-wrapper mt-5 mt-lg-0">
											<div
												className="map-base"
												style={{
													backgroundImage: `url(${this.props.data.imageSharp.fluid.src})`,
												}}
											>
												{geographic_area.map((region, index) => (
													<BackgroundImage key={index} src={_.kebabCase(region)} />
												))}
											</div>

											<div className="story-map-legend-container mt-5">
												<ul>
													{geographic_area.map((region, index) => (
														<li key={index}>
															<a href={`/search?geographic_area=${region}`}>
																<span className={`${_.kebabCase(region)}--color-scheme legend-indicator`} />
																<span className="label">{region}</span>
															</a>
														</li>
													))}
												</ul>
											</div>
										</div>
									</Col>
								)}
							</Row>
						</Container>
						{story_sources && (
							<Container fluid="lg">
								<Row className="justify-content-center">
									<Col lg={8}>
										<div className="story-sources-wrapper text-center">
											<p className="sources-heading text-uppercase font-weight-semi">Sources:</p>
										</div>
										<div className="story-sources-content" dangerouslySetInnerHTML={{ __html: story_sources }} />
									</Col>
								</Row>
							</Container>
						)}

						<Container>
							<Row>
								<Col>
									<div className="story-watch-series text-center d-print-none mt-5 pt-3">
										<WatchSeries link={this.props.data.site.siteMetadata.watch_series_link} />
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className={`story-pre-footer reveal-animate d-print-none ${color_scheme}`}>
						<Container>
							<Row className="justify-content-center">
								<Col xs={12} md={4} className="text-center">
									<a href={backLink} className="back-link">
										<span className="explore-arrow position-relative">
											<span className="long-arrow left-arrow" />
											{label && <span className="label">{label}</span>}
										</span>
									</a>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
				<SocialShare dataOptions={dataOptions} />
				<FooterStory colorScheme={color_scheme}>
					{rights_license}
				</FooterStory>
			</Layout>
		);
	}
}

export default Story;

Story.defaultProps = {
	location: {
		state: {
			fromSearch: false,
		},
	},
};

Story.propTypes = {
	location: PropTypes.object.isRequired,
};

NextArrow.propTypes = {
	onClick: PropTypes.func
};

PrevArrow.propTypes = {
	onClick: PropTypes.func
};

export const query = graphql`
	query($id: String) {
		site {
			siteMetadata {
				title
				watch_series_link
			}
		}

		stories(story_id: { eq: $id }) {
			story_id
			name
			decade
			deck
			geographic_area
			heritage
			keywords
			story_stream
			featured_image {
				image {
					featured
				}
			}
			display_date
			story_content
			story_sources
			color_scheme
			language
			coverage
			publisher
			rights_license
			author
			media {
				id
				name
				type
				description
				display_date
				source
				unique_identifier
				author
				media_type
				language
				coverage
				rights_license
				publisher
				media_id
				preview_url
				embed_url
				thumbnail_url
				image {
					gallery
					thumbnail
				}
				caption
			}
		}

		imageSharp(fluid: { originalName: { eq: "map-base.png" } }) {
			fluid {
				src
			}
		}
	}
`;
